import React from 'react'
import { Link, navigate } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import { Section, Button, Title, Text, Box } from '../components/Core'
import PageWrapper from '../components/PageWrapper'
import Contact from '../sections/common/Contact'
import cryptoAppCover from '../assets/image/cover/CryptoAppNew.webp'
import android1 from '../assets/image/details/crypto-app/2screens.webp'
import android2 from '../assets/image/details/crypto-app/Multiscreens.webp'


const handleLiveWorkClicked = event => {
  event.stopPropagation()
  navigate('/case-studies')
}
const WorkSingle = () => {
  return (
    <>
      <PageWrapper>
        <Container
          fluid
          className="px-sm-5"
          css={`
            margin-top: 92px;
          `}
        >
          <img src={cryptoAppCover} alt="" className="img-fluid w-100" />
        </Container>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">UI/UX</Text>
                <Title variant="secSm" className="my-4">
                  Project Overview{' '}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  {' '}
                 Universe
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Client</Text>
                <Title variant="cardBig" className="mt-3">
                  Universum
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Time Scope</Text>
                <Title variant="cardBig" className="mt-3">
                  1 month
                </Title>
              </Col>
              {/* <Col lg="4">
                <Button onClick={handleLiveWorkClicked} arrowRight>
                  Live work
                </Button>
              </Col> */}
            </Row>
          </Container>
        </div>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              {/*         
              {imgS2} alt="" className="img-fluid w-100" />
              </Col> */}
              <Col xs="12" className="mb-5">
                <img src={android1} alt="" className="img-fluid w-100" />
              </Col>

              <Col xs="12" className="pl-lg-4">
                <img src={android2} alt="" className="img-fluid w-100" />
              </Col>

            </Row>
          </Container>
        </Section>
        <Section className="pt-4 pb-0">
          <Container>
            <Title variant="secSm" className="mb-5 pb-lg-4">
              Process
            </Title>
            <Row>
              <Col lg="6" className="mb-5 pr-lg-5">
                <Title variant="cardBig" className="mb-4">
                  1. Briefing
                </Title>
                <Text variant="p">
                  Designing a cryptocurrency wallet for both Android and iOS platforms.
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pl-lg-5">
                <Title variant="cardBig" className="mb-4">
                  2. Deliverables
                </Title>
                <Text variant="p">
                  - Research <br />
                  - Onboarding flows <br />
                  - Wireframing<br />
                  - UI/UX Designs <br />
                  - User Testing <br />
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pr-lg-5">
                <Title variant="cardBig" className="mb-4">
                  3. Strategy
                </Title>
                <Text variant="p">
                  Analyzing design patterns in existing crypto currency wallet apps.
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pl-lg-5">
                <Title variant="cardBig" className="mb-4">
                  4. Key Learnings
                </Title>
                <Text variant="p">
                  Familiarizing myself with iOS and Android User Interface Design Guidelines.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        {/* <Section mt="22%" bg="dark">
          <Container>
            {/* <Row

              css={`
                margin-top: -40%;
              `}
            >
              { <Col xs="12" className="mb-5">
                <img src={imgS5} alt="" className="img-fluid w-100" />
              </Col>}
            </Row> }

            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="portfolio-details">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  Replace
                </Button>
              </Link>
            </div>
          </Container>
        </Section> */}
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  )
}
export default WorkSingle
